import React from 'react'
import RegisterForm from './registerForm'
import { withTranslation } from 'react-i18next'

class StyledRegister extends React.Component {
  render () {
    const { formSaving, registerUser, isMobile } = this.props

    return (
      <RegisterForm
        onHandleSubmit={registerUser}
        formSaving={formSaving}
        isMobile={isMobile}
      />
    )
  }
}

export default withTranslation()(StyledRegister)
