import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

import ScrollToTop from './components/common/scrollToTop'
// import Main from './components/main'
import PanelIndex from './components/main/panel'
import Register from './components/user/register'
import UserLogin from './components/user/login'
import { setAuth, } from './store/actions'
import { getDeviceData } from './utils/helpers'
import withTracker from './utils/withTracker'

class Routes extends React.Component {
  componentDidMount () {
    const { setAuth } = this.props
    const token = getDeviceData('auth')

    if (token) {
      setAuth(true)
    }
  }

  render () {
    const { authenticated } = this.props

    const RegisterWithTracker = withTracker(Register)

    return (
      <>
        <ScrollToTop />
        {authenticated
          ? (
            <Switch>
              <Route exact path='/' component={withTracker(PanelIndex)} />
              <Route exact path='/login' component={withTracker(UserLogin)} />
            </Switch>)
          : (
            <Switch>
              // <Route exact path='/register' render={(props) => <RegisterWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/login' component={withTracker(UserLogin)} />
              {window.matchMedia('(display-mode: standalone)').matches ? (
                <Route exact path='/' component={withTracker(UserLogin)} />
              ) : (
                <Route component={withTracker(UserLogin)} />
              )}
            </Switch>
          )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated
})

const mapDispatchToProps = {
  setAuth
}

const RoutesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Routes)

export default RoutesContainer
