import { createStore } from 'redux'
import reducers from './reducers'

function configureStore (initialState = {}) {
  const store = createStore(reducers, initialState)
  return store
}

const store = configureStore()
export default store
