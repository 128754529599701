/* eslint-disable camelcase */
import React from 'react'
import ReactDOM from 'react-dom'
import './assets/scss/index.scss'
import './assets/scss/motion_modules/motion.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './i18n'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { ConfigProvider } from 'antd'
import pl_PL from 'antd/es/locale/pl_PL'

import { Provider } from 'react-redux'
import store from './store/'

// import ReactGA from 'react-ga4'
// ReactGA.initialize('G-EPGKEBLRPT')//, {testMode:true})
// ReactGA.send('pageview')

// if (window.matchMedia('(display-mode: standalone)').matches) {
//   ReactGA.event({ category: 'App', action: 'Installed version of app opened' })
// }

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={pl_PL}>
      <Provider store={store}>
        <App />
      </Provider>
    </ConfigProvider>

  </React.StrictMode>,
  document.getElementById('root')
)

serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
