import axios from 'axios'
import { openNotification, getDeviceData, saveDeviceData, removeDeviceData } from './helpers'
import settings from '../config'
import { setAuth } from '../store/actions'
import store from '../store'

axios.defaults.validateStatus = function (status) {
  return status >= 200 && status < 500
}

// intercept request to add authorization token
axios.interceptors.request.use(config => {
  const token = getDeviceData('auth')
  if (token) {
    config.headers.Authorization = 'Bearer ' + token
  }
  config.headers.common['X-LOCALE'] = 'pl_PL'
  return config
}, error => {
  Promise.reject(error)
})

// intercept response to catch http:401 to call refresh token or to logout user when refresh token is dead
axios.interceptors.response.use((response) => {
  if (response) {
    const originalRequest = response.config

    if (response.status === 401 && new URL(originalRequest.url).pathname === '/api/token/refresh') {
      // logout user
      store.dispatch(setAuth(false))
      removeDeviceData('auth')
      removeDeviceData('refresh_token')
      throw new axios.Cancel('Session expired')
    }

    if (response.status === 401 && new URL(originalRequest.url).pathname !== '/api/login_check' && new URL(originalRequest.url).pathname !== '/api/api/login_check') {
      return axios.post(`${settings.api_url}/api/token/refresh`, { refresh_token: getDeviceData('refresh_token') })
        .then(res => {
          if (res.status === 200) {
            saveDeviceData('auth', res.data.token)
            saveDeviceData('refresh_token', res.data.refresh_token)
            return axios(originalRequest)
          }
        })
    }
  }

  return response
}, (error) => {
  return Promise.reject(error)
})

export function getServers () {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/server/`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getServer (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/server/${id}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function updateServer (id, data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/server/${id}`,
      data: data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function addServer (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/server/`,
      data: data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function authenticateUser (username, password) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/login_check`,
      data: {
        username: username,
        password: password
      }
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function registerUser (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/register`,
      data: data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getUserProfile () {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/user/profile`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function updateUserProfile (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/user/profile/save`,
      data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function logoutUser () {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/logout`,
      data: {
        refresh_token: getDeviceData('refresh_token')
      }
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function dummy () {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/data`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function saveOrdersConfig (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/orders_config/save`,
      data: { data }
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getOrdersConfig (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/orders_config`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function calculateCalcPricing (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/calc_pricing/calculate`,
      data: { data }
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function saveCalcPricing (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/calc_pricing/save`,
      data: data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    }).catch(function (error) {
      reject(error)
    })
  })
}

export function saveSubmitCalcPricing (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/calc_pricing/submit_save`,
      data: data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    }).catch(function (error) {
      reject(error)
    })
  })
}

export function getCalcPricing (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/calc_pricing`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function savePromoUse (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/promo/${id}/save`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.data)
      }
    }).catch(function (error) {
      reject(error)
    })
  })
}

export function getOrders () {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/order`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getOrdersForDay (day) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/order/to_date/${day}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getOrder (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/order/${id}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function updateOrder (id, data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/order/${id}`,
      data: data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function deleteOrder (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/order/${id}/delete`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function addOrder (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/order/add`,
      data: data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getOrderQuotation (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/order/quotation`,
      data: data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function setOrderStatus (id, status) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/order/${id}/status`,
      data: { status }
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getPantry () {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/pantry`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getPantryItem (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/pantry/${id}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function updatePantryItem (id, data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/pantry/${id}`,
      data: data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function deletePantryItem (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/pantry/${id}/delete`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function addPantry (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/pantry/add`,
      data: data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getRecipes () {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/recipe`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function addRecipe (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/recipe/add`,
      data: data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function updateRecipe (id, data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/recipe/${id}`,
      data: data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function deleteRecipe (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/recipe/${id}/delete`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getRecipe (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/recipe/${id}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}
