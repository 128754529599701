import React from 'react'
import {
  Form, Input, Checkbox, Button, Divider, Space, Select
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const formItemLayout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 }
}

const { Option } = Select

class StyledRegisterForm extends React.Component {
  formRef = React.createRef()

  setDefaultValues = () => {
    this.formRef.current.setFieldsValue({
      password: '',
      password_repeat: '',
      agreement: false
    })
  }

  componentDidMount () {
    this.setDefaultValues()
  }

  render () {
    const { onHandleSubmit, formSaving } = this.props

    return (
      <Form onFinish={onHandleSubmit} className='register-form' layout='horizontal' ref={this.formRef}>
        <Form.Item
          name='email'
          label={this.props.t('Email')}
          rules={[{
            required: true,
            type: 'email',
            message: this.props.t('Valid email address is required')
          }]}
          {...formItemLayout}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='password'
          label={this.props.t('Password')}
          rules={[{ required: true, message: this.props.t('Password field is required') }]}
          {...formItemLayout}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name='password_repeat'
          label={this.props.t('Repeat password')}
          rules={[{ required: true, message: this.props.t('Password field is required') }]}
          {...formItemLayout}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name='group_password'
          label={this.props.t('FB group password')}
          rules={[{ required: true, message: this.props.t('Group password field is required') }]}
          {...formItemLayout}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='province'
          label={this.props.t('Province')}
          rules={[{ required: true, message: this.props.t('Province field is required') }]}
          {...formItemLayout}
        >
          <Select placeholder={this.props.t('Select province')}>
            <Option value='1'>dolnośląskie</Option>
            <Option value='2'>kujawsko-pomorskie</Option>
            <Option value='3'>lubelskie</Option>
            <Option value='4'>lubuskie</Option>
            <Option value='5'>łódzkie</Option>
            <Option value='6'>małopolskie</Option>
            <Option value='7'>mazowieckie</Option>
            <Option value='8'>opolskie</Option>
            <Option value='9'>podkarpackie</Option>
            <Option value='10'>podlaskie</Option>
            <Option value='11'>pomorskie</Option>
            <Option value='12'>śląskie</Option>
            <Option value='13'>świętokrzyskie</Option>
            <Option value='14'>mazurskie</Option>
            <Option value='15'>wielkopolskie</Option>
            <Option value='16'>zachodniopomorskie</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name='town'
          label={this.props.t('Town')}
          rules={[{ required: true, message: this.props.t('Town field is required') }]}
          {...formItemLayout}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='newsletter_agreement'
          colon={false}
          valuePropName='checked'
        >
          <Checkbox>{this.props.t('I want to receive news notifications about news in application')}</Checkbox>
        </Form.Item>
        <Form.Item
          name='agreement'
          colon={false}
          rules={[{
            required: true,
            transform: value => (value || undefined),
            type: 'boolean',
            message: this.props.t('Agreement of Regulations and Privacy policy field is required')
          }]}
          valuePropName='checked'
        >
          <Checkbox className='register-agreement-checkbox'>{this.props.t('I accept')} <Link to='/regulations'>{this.props.t('Regulations')}</Link> {this.props.t('and')} <Link to='/privacy-policy'>{this.props.t('Privacy policy')}</Link></Checkbox>
        </Form.Item>

        <Divider />
        <Space>
          <Button htmlType='submit' disabled={formSaving}>{this.props.t('Register')}{formSaving && <LoadingOutlined style={{ fontSize: 14 }} spin />}</Button>
        </Space>
      </Form>
    )
  }
}

export default withTranslation()(StyledRegisterForm)
