import { combineReducers } from 'redux'

const appRedux = (state = {}, action) => {
  switch (action.type) {
    case 'SET_AUTH':
      return {
        ...state,
        authenticated: action.authenticated
      }
    case 'SET_INSTALL_PROMPT_SHOWED':
      return {
        ...state,
        installPromptShowed: action.installPromptShowed
      }
    default:
      return state
  }
}

const reducers = combineReducers({
  appRedux
})

export default reducers
