import { Menu } from 'antd'
import React from 'react'
import { LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { Link as LinkScroll } from 'react-scroll'
import { Link, withRouter } from 'react-router-dom'

class StyledMenuMain extends React.Component {
  hideMobileMenu = () => {
    this.props.onHideMobileMenu()
  }

  render () {
    const { isMobile } = this.props

    return (
      this.props.isAuthenticated ? (
        <Menu mode={!isMobile ? 'horizontal' : 'inline'} onClick={isMobile && this.hideMobileMenu} disabledOverflow>
          <Menu.Item key='desktop'>
            <Link to='/'>Pulpit</Link>
          </Menu.Item>

          <Menu.SubMenu key='profile' className='menu-profile' title={isMobile ? 'Moje konto' : <UserOutlined />}>
            <Menu.Item key='logout' onClick={this.props.handleLogout} icon={<LogoutOutlined />}>
              Wyloguj
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
      ) : ''
    )
  }
}

export default withRouter(StyledMenuMain)
