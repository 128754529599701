/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react'
import { Statistic, Row, Col } from 'antd'
import { enquireScreen } from 'enquire-js'
import { dummy } from '../../utils/actions'

const { location = {} } = typeof window !== 'undefined' ? window : {}

class PanelIndex extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isMobile: undefined,
      show: !location.port
    }
  }

  dummy = () => {
    dummy().then((json) => {
      this.setState({ data: json })
      setTimeout(this.dummy, 600000)
    })
    // .catch((err) => {
    //   console.err(err)
    //   setTimeout(this.dummy, 10000)
    // })
  }

  componentDidMount () {

    this.dummy()
  }
  render () {
    return (
      <div style={{ marginTop: '50px' }}>
      <Row gutter={16}>
          {this.state.data && Object.keys(this.state.data?.data).map((key) => {
          return <Col span={6}><Statistic title={key} value={this.state.data?.data[key]} /></Col>
        })}
         </Row>
      </div>
    )
  }
}

export default PanelIndex
