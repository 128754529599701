import React from 'react'
import { PageHeader, Alert } from 'antd'
import { registerUser } from '../../utils/actions'
import StyledRegister from '../../styledComponents/user/register'
import { openNotification } from '../../utils/helpers'
import { Redirect } from 'react-router'
import QueueAnim from 'rc-queue-anim'
import { withTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'

class Register extends React.Component {
  state = {
    makeRedirection: false,
    formSaving: false
  }

  componentDidMount (prevProps, prevState) {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  registerUser = (data) => {
    this.setState({ formSaving: true })

    ReactGA.event({ category: 'User', action: 'Register' })

    registerUser(data).then((json) => {
      if (this._isMounted) {
        this.setState({ formSaving: false })
        openNotification(this.props.t('Success'), this.props.t('You has been successfully registered, you can log in now!'))
        this.makeRedirection()
      }
    }).catch(() => {
      if (this._isMounted) {
        this.setState({ formSaving: false })
      }
    })
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  render () {
    const { makeRedirection, formSaving } = this.state
    const { authenticated, setAuth, ...currentProps } = this.props

    return (
      makeRedirection ? (
        <Redirect to='/login' />
      ) : (
        <div {...currentProps} className='banner2'>
          <PageHeader
            className='back-to-hompage'
            onBack={() => this.makeRedirection()}
            title={this.props.t('Register')}
          />
          <QueueAnim
            key='QueueAnim'
            type={['bottom', 'top']}
            delay={200}
            className='banner2-text-wrapper'
          >
            <div key='content' className='banner2-content'>
              <Alert
                className='registration-alert'
                closable
                message={
                  <>
                    {this.props.t('Current version of application is only available for \'Torty i wypieki - Inspiracje Cukiernicze\' fb group members. Visit')}{' '}
                    {/*this.props.t('Obecnie aplikacja jest w fazie testów, rejestracja jeszcze nie jest dostępna dla użytkowników. Zapraszamy wkrótce!')}{' '*/}
                    <a
                      href='https://www.facebook.com/groups/1719093458165351'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {this.props.t('group page')}
                    </a> {this.props.t('and ask administrator for secret password before registration.')}
                  </>
                }
                type='info'
              />
              <StyledRegister
                makeRedirection={this.makeRedirection}
                registerUser={this.registerUser}
                formSaving={formSaving}
                isMobile={this.props.isMobile}
              />
            </div>
          </QueueAnim>
        </div>)
    )
  }
}

export default withTranslation()(Register)
