import React from 'react'
import { connect } from 'react-redux'
import { notification } from 'antd'
import { removeDeviceData, openNotification } from '../../utils/helpers'
import { setAuth } from '../../store/actions'
import { logoutUser } from '../../utils/actions'
import StyledHeader from '../../styledComponents/common/header'
import { withTranslation } from 'react-i18next'
import { Redirect } from 'react-router'

class Header extends React.Component {
  state = {
    makeRedirection: false
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  componentDidMount () {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      window.addEventListener('offline', () => {
        notification.warning({
          message: 'Jesteś offline',
          duration: 0,
          description: 'Zmiany w aplikacji nie będą zapisywane z powodu braku połączenia z siecią',
          placement: 'topRight'
        })
      })

      window.addEventListener('online', () => {
        notification.info({
          message: 'Ponownie jesteś online',
          duration: 0,
          placement: 'topRight'
        })
      })
    }
  }

  logout = () => {
    const { setAuth } = this.props

    logoutUser().then(() => {
      removeDeviceData('auth')
      removeDeviceData('refresh_token')
      setAuth(false)
      openNotification(this.props.t('You has been logged out'), this.props.t('See you later!'))
      this.makeRedirection()
    })
  }

  render () {
    const { authenticated, isMobile } = this.props
    const { makeRedirection } = this.state

    return (
      <>
        {makeRedirection ? (
          <Redirect to='/' />
        ) : ''}
        <StyledHeader isAuthenticated={authenticated} logout={this.logout} />
      </>
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated
})

const mapDispatchToProps = {
  setAuth
}

const HeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)

export default withTranslation()(HeaderContainer)
