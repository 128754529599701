import React from 'react'
import { notification, Button } from 'antd'
import moment from 'moment'

export function getCookie (cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
}

export function firstLetterUpperCase (text) {
  return text.charAt(0).toUpperCase() + text.substr(1);
}

export function setCookie (cname, cvalue, expireInDays) {
  var d = new Date()
  d.setTime(d.getTime() + expireInDays * 86400000)
  var expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

export function deleteCookie (cname) {
  setCookie(cname, '', '')
}

export function getDeviceData (key) {
  return window.localStorage.getItem(key)
}

export function saveDeviceData (key, value) {
  window.localStorage.setItem(key, value)
}

export function removeDeviceData (key) {
  window.localStorage.removeItem(key)
}

export function openNotification (title, message) {
  return notification.open({
    message: title,
    description: message,
    duration: 2.5
  })
}

export const isImg = /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?/

export const getChildrenToRender = (item, i) => {
  let tag = item.name.indexOf('title') === 0 ? 'h1' : 'div'
  tag = item.href ? 'div' : tag
  let children = typeof item.children === 'string' && item.children.match(isImg)
    ? React.createElement('img', { src: item.children, alt: 'img' })
    : item.children
  if (item.name.indexOf('button') === 0 && typeof item.children === 'object') {
    children = React.createElement(Button, {
      ...item.children
    })
  }
  return React.createElement(tag, { key: i.toString(), ...item }, children)
}

export const niclyFormatDate = (year, month, day) => {
  const monthName = moment(year + '-' + month + '-' + day, 'YYYY-M-D').format('MMMM')
  const currentYear = moment().format('YYYY')

  if (year === currentYear) {
    if (day) {
      return day + ' ' + monthName
    } else {
      return monthName
    }
  } else {
    if (day) {
      return day + ' ' + monthName + ' ' + year
    } else {
      return monthName + ' ' + year
    }
  }
}

export const niclyFormatPortions = (portions) => {
  const p = String(portions)

  if (p === '1') {
    return '1 porcja'
  } else if (['2', '3', '4'].includes(p[p.length - 1])) {
    return p + ' porcje'
  } else {
    return p + ' porcji'
  }
}

export const getOrderStatusColor = (status) => {
  if (status === 'finished') {
    return 'orange'
  } else if (status === 'canceled') {
    return 'gray'
  } else {
    return 'green'
  }
}
