import React from 'react'
import { PageHeader } from 'antd'
import QueueAnim from 'rc-queue-anim'
import StyledUserLogin from '../../styledComponents/user/loginForm'
import { withTranslation } from 'react-i18next'
import { setAuth } from '../../store/actions'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'

class UserLogin extends React.PureComponent {
  state = {
    makeRedirection: false
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  render () {
    const { makeRedirection } = this.state
    const { authenticated, setAuth, ...currentProps } = this.props

    return (
      makeRedirection || authenticated ? (
        <Redirect to='/' />
      ) : (
        <div {...currentProps} className='banner1'>
          <PageHeader
            className='back-to-hompage'
            // onBack={!window.matchMedia('(display-mode: standalone)').matches ? this.makeRedirection : null}
            title={this.props.t('Log in')}
          />
          <QueueAnim
            key='QueueAnim'
            type={['bottom', 'top']}
            delay={200}
            className='banner1-text-wrapper'
          >
            <div key='content' className='banner1-content'>
              <StyledUserLogin
                setAuth={setAuth}
                makeRedirection={this.makeRedirection}
              />
            </div>
          </QueueAnim>
        </div>)
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated
})

const mapDispatchToProps = {
  setAuth
}

const RoutesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserLogin)

export default withTranslation()(RoutesContainer)
