import React from 'react'
import { HeartOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'

class Footer extends React.Component {
  render () {
    return (
      <div id='PanelFooter1_0' key='PanelFooter1_0' className='home-page-wrapper footer0-wrapper'>
        <div className='footer0'>
          <span>
            ©2022 by BIZMON {!this.props.isMobile ? '| made with ' : ''}<HeartOutlined /> by <a href='https://blue-it.pl'>blue-it.pl</a>
          </span>
        </div>
      </div>
    )
  }
}

export default withRouter(Footer)
