import React, { useEffect, useState } from 'react'

function useScreenWidth (): number {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handler = (event: any) => {
      setWidth(event.target.innerWidth)
    }

    window.addEventListener('resize', handler)

    return () => {
      window.removeEventListener('resize', handler)
    }
  }, [])

  return width
}

export const menuTypeHook = (Component: any) => {
  return (props: any) => {
    const screenWidth = useScreenWidth()
    let menuType
    let screenSize

    if (screenWidth > 768) {
      menuType = 'horizontal'
    } else {
      menuType = 'inline'
    }

    if (screenWidth < 576) {
      screenSize = 'xs'
    } else if (screenWidth < 768) {
      screenSize = 'sm'
    } else if (screenWidth < 992) {
      screenSize = 'md'
    } else if (screenWidth < 1200) {
      screenSize = 'lg'
    } else if (screenWidth < 1600) {
      screenSize = 'xl'
    } else {
      screenSize = 'xxl'
    }

    return <Component width={screenWidth} menuType={menuType} screenSize={screenSize} {...props} />
  }
}
